

const list_surat = [
  {
    label: "Al Faatihah",
    value: "Al Faatihah",
    ayat: 7
  },
  {
    label: "Al Baqarah",
    value: "Al Baqarah",
    ayat: 286
  },
  {
    label: "Ali ‘Imran",
    value: "Ali ‘Imran",
    ayat: 200
  },
]

export default list_surat