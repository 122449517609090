<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-tabs
        v-model="tab"
        no-caps
        class="bg-primary text-white"
        indicator-color="orange"
      >
        <q-tab name="setoran" label="SETORAN" />
        <q-tab name="ujian" label="UJIAN" />
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="setoran" class="q-pa-none">
          <q-card-section class="q-gutter-md">
            <q-select
              outlined
              v-if="user.is_super_user == '1'"
              v-model="selMusyrif"
              label="Musyrif"
              :options="musyrif"
              @input="getSiswaList"
            />
            <q-select
              outlined
              v-model="selSiswa"
              label="Siswa"
              :options="siswa"
              @input="getSetoran(), getnilaiujian()"
            />
            <q-btn
              flat
              dense
              color="primary"
              label="Absensi Siswa Masal"
              to="/penilaiantahfidzabsensi"
            ></q-btn>
            <q-input
              outlined
              readonly
              v-model="date"
              :disable="selSiswa == null"
            >
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="date" mask="YYYY-MM-DD">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <p class="row justify-between" v-if="patok.id != null">
              <a>Setoran Ke-</a>
              <a>
                : {{ patok.number + 1 }}
                <q-btn
                  flat
                  color="negative"
                  size="sm"
                  label="batal"
                  @click="clear"
                ></q-btn>
              </a>
            </p>
            <div class="row justify-between">
              <q-input
                outlined
                v-model="setoran.dariSurat"
                :disable="selSiswa == null"
                label="Dari Surat"
                style="width:65%"
              ></q-input>
              <!-- <q-select
                :disable="selSiswa == null"
                @filter="filterFnSurat"
                outlined
                v-model="setoran.dariSurat"
                label="Dari Surat"
                :options="filteredListSurat"
                style="width:65%"
              />-->
              <q-input
                outlined
                v-model="setoran.dariAyat"
                :disable="selSiswa == null"
                label="Ayat"
                style="width:30%"
              ></q-input>
            </div>
            <div class="row justify-between">
              <q-input
                outlined
                v-model="setoran.hinggaSurat"
                :disable="selSiswa == null"
                label="Hingga Surat"
                style="width:65%"
              ></q-input>
              <q-input
                outlined
                v-model="setoran.hinggaAyat"
                :disable="selSiswa == null"
                label="Ayat"
                style="width:30%"
              ></q-input>
            </div>
            <q-input
              outlined
              :disable="selSiswa == null"
              label="Halaman Awal"
              v-model="setoran.halaman_awal"
            ></q-input>
            <q-input
              outlined
              :disable="selSiswa == null"
              label="Halaman Akhir"
              v-model="setoran.halaman_akhir"
            ></q-input>
          </q-card-section>
          <q-card-actions class="q-px-md">
            <div
              class="q-gutter-sm"
              v-if="patok.id != null"
              style="width:100%; display:contents"
            >
              <q-btn
                outline
                color="negative"
                class="full-width"
                @click="alert = true"
                >hapus</q-btn
              >
              <q-btn
                unelevated
                color="primary"
                class="full-width"
                @click="edit()"
                >edit</q-btn
              >
            </div>
            <q-btn
              v-else
              unelevated
              color="primary"
              class="full-width q-mt-md"
              @click="simpan()"
              :disable="selSiswa == null"
              >simpan</q-btn
            >
          </q-card-actions>
          <q-markup-table
            flat
            dense
            separator="horizontal"
            class="stickyTable q-mt-md no-border-radius q-mt-xl bg-grey-4"
            style="height:calc(100vh - 200px);"
          >
            <thead class="bg-indigo-5 text-white text-left">
              <tr class="text-left">
                <th>No</th>
                <th>Tanggal</th>
                <th>Surat</th>
                <th>Ayat</th>
                <th>Surat</th>
                <th>Ayat</th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-if="selSiswa == null">
                <td class="text-center" colspan="6">
                  Silahkan Pilih Siswa Dahulu
                </td>
              </tr>
              <tr v-else-if="tanggal.length == 0">
                <td class="text-center" colspan="6">
                  Belum ada Riwayat Setoran Hafalan
                </td>
              </tr>
              <tr v-for="(val, i) in tanggal" :key="i" @click="pilih(val, i)">
                <td>{{ i + 1 }}</td>
                <td>{{ val.tanggal | moment("DD-MMM-YYYY") }}</td>
                <td>{{ nullFilter(val.tampil[0]) }}</td>
                <td>{{ nullFilter(val.tampil[1]) }}</td>
                <td>{{ nullFilter(val.tampil[2]) }}</td>
                <td>{{ nullFilter(val.tampil[3]) }}</td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-card-actions>
            <q-btn
              v-if="selSiswa != null"
              unelevated
              color="secondary"
              class="full-width"
              label="RIWAYAT"
              :disable="selSiswa == null"
              :to="
                '/penilaianriwayattahfidz/' +
                  selSiswa.value +
                  '/' +
                  selSiswa.label
              "
            ></q-btn>
          </q-card-actions>
        </q-tab-panel>
        <q-tab-panel name="ujian" class="q-pa-none">
          <q-card-section
            class="q-gutter-md"
            style="height : calc(100vh - 215px)"
          >
            <q-select
              outlined
              v-if="user.is_super_user == '1'"
              v-model="selMusyrif"
              label="Musyrif"
              :options="musyrif"
              @input="getSiswaList"
            />
            <q-select
              outlined
              v-model="selSiswa"
              label="Siswa"
              :options="siswa"
              @input="getSetoran(), getnilaiujian()"
            />
            <div class="row justify-around">
              <q-radio
                v-model="tipe"
                :disable="selSiswa == null"
                val="UTS"
                label="UTS"
                @input="getnilaiujian"
              />
              <q-radio
                v-model="tipe"
                :disable="selSiswa == null"
                val="UAS"
                label="UAS"
                @input="getnilaiujian"
              />
            </div>
            <q-input
              outlined
              stack-label
              type="number"
              v-model="ujian.nilai"
              :disable="selSiswa == null"
              label="nilai"
            ></q-input>
          </q-card-section>
          <q-card-actions class="q-px-md">
            <q-btn
              outline
              :disable="selSiswa == null"
              @click="toRemed"
              label="Remedial"
              color="negative"
              class="full-width no-border-radius q-mb-sm"
            ></q-btn>
            <q-btn
              unelevated
              color="primary"
              class="full-width no-border-radius"
              @click="simpan_ujian()"
              :disable="selSiswa == null"
              >simpan</q-btn
            >
          </q-card-actions>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-dialog persistent v-model="alert">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="report_prblem" color="negative" text-color="white" />
          <span class="q-ml-sm">
            <strong class="q-ml-sm">
              Apa anda yakin ingin menghapus Setoran ke-{{ patok.number + 1 }}
              ?
            </strong>
          </span>
        </q-card-section>
        <q-card-section>
          <p>
            silahkan tulis "Setoran {{ patok.number + 1 }}" tanpa tanda kutip
            untuk melanjutkan
          </p>
          <q-input outlined v-model="pass"></q-input>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="Tidak"
            @click="pass = ''"
            v-close-popup
          />
          <q-btn
            unelevated
            :disable="
              !(this.pass.toLowerCase() == 'setoran ' + (patok.number + 1))
            "
            color="primary"
            label="Ya"
            @click="hapus(), (pass = '')"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import List_Surat from "@/views/PenilaianRiwayatAbsensi/List_Surat.js";
export default {
  data() {
    return {
      tab: "setoran",

      musyrif: [],
      selMusyrif: {
        value: null,
      },
      user: {},

      kkm: 80, //kkm sementara
      tipe: "UTS",
      ujian: {
        nilai: null,
        id: null,
      },
      nilai_remed: null,
      listRemed: [],

      alert: false,
      pass: "",

      patok: {
        id: null,
        number: null,
      },
      setoran: {
        dariSurat: null,
        dariAyat: null,
        hinggaSurat: null,
        hinggaAyat: null,
      },
      date: null,

      selSiswa: null,
      siswa: [],
      tanggal: [],
      searchTerm: "",
      filteredListSurat: [],
    };
  },
  async mounted() {
    await this.getUser();
    if (this.user.is_super_user == "1") {
      await this.getMusyrif();
    } else {
      this.selMusyrif.value = this.user.id;
    }
    await this.getSiswaList();
    this.date = moment().format("YYYY-MM-DD");
    if (this.$route.params.id > 0) {
      this.selSiswa = this.siswa.find(
        ({ value }) => value == this.$route.params.id
      );
      this.tipe = this.$route.params.tipe;
      await this.getnilaiujian();
    }
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getMusyrif() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(`/penilaian/absensi/getmusyrif`);
      this.musyrif = resp.data;
      if (resp.data.length > 0) {
        this.selMusyrif = resp.data[0];
      }
      this.$q.loading.hide();
    },
    nullFilter(word) {
      if (word == "null") {
        return "-";
      } else {
        return word;
      }
    },
    nullFilterV2(word) {
      if (word == "null") {
        return null;
      } else {
        return word;
      }
    },
    toRemed() {
      this.$router.push(
        `/penilaianremedialtahfidz/${this.selSiswa.value}/${this.tipe}`
      );
    },
    filterFnSurat(val, cb) {
      if (val == "") {
        cb(() => {
          this.filteredListSurat = JSON.parse(JSON.stringify(List_Surat));
        });
        return;
      }

      cb(() => {
        const needle = val.toLowerCase();
        this.filteredListSurat = List_Surat.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    async getListRemed() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      if (this.ujian.id == null) {
        this.$q.loading.hide();
        return;
      }
      let resp = await this.$http.get(
        `/penilaian/tahfidz/getlistremed/${this.ujian.id}`
      );
      this.listRemed = resp.data;
      this.$q.loading.hide();
    },
    async remed() {
      await this.$http.post(
        `/penilaian/tahfidz/remed/${this.ujian.id}/${this.ujian.nilai}/${this.nilai_remed}`
      );
      this.getnilaiujian();
      this.getListRemed();
    },
    async simpan_ujian() {
      let bundle = {
        id_siswa: this.selSiswa.value,
        id_tahun_ajaran: localStorage.getItem("id_tahun_ajaran"),
        tipe: this.tipe,
        nilai: this.ujian.nilai,
      };
      let nama = "";
      let id = 0;
      if (this.user.is_super_user == "1") {
        nama = this.selMusyrif.label;
        id = this.selMusyrif.value;
      } else {
        nama = this.user.nama;
        id = this.user.id;
      }
      await this.$http.post(
        `/penilaian/tahfidz/addujian/${nama}/${id}`,
        bundle
      );
      this.getnilaiujian();
    },
    async getnilaiujian() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.ujian = {
        nilai: null,
        id: null,
      };
      this.nilai_remed = null;
      this.listRemed = [];
      let resp = await this.$http.get(
        `/penilaian/tahfidz/getujian/${this.selSiswa.value}/${this.tipe}`
      );
      if (resp.data.length > 0) {
        this.ujian = resp.data[0];
      }
      this.$q.loading.hide();
      this.getListRemed();
    },
    async hapus() {
      await this.$http.delete(`/penilaian/tahfidz/hapus/${this.patok.id}`);
      this.getSetoran();
    },
    clear() {
      this.patok = {
        id: null,
        number: null,
      };

      this.setoran = {
        dariSurat: null,
        dariAyat: null,
        hinggaSurat: null,
        hinggaAyat: null,
      };
    },
    pilih(val, i) {
      this.setoran = {
        dariSurat: this.nullFilterV2(val.tampil[0]),
        dariAyat: this.nullFilterV2(val.tampil[1]),
        hinggaSurat: this.nullFilterV2(val.tampil[2]),
        hinggaAyat: this.nullFilterV2(val.tampil[3]),
        halaman_awal: this.nullFilterV2(val.halaman_awal),
        halaman_akhir: this.nullFilterV2(val.halaman_akhir),
      };
      this.patok = {
        id: val.id,
        number: i,
      };
    },
    async getSetoran() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/tahfidz/getsetoran/${
          this.selSiswa.value
        }/${localStorage.getItem("id_tahun_ajaran")}`
      );

      for (let element of resp.data) {
        let temp = [];
        temp = element.setoran.split("%");
        element.tampil = temp;
      }
      this.tanggal = resp.data;

      this.$q.loading.hide();
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/tahfidz/getsiswa/${this.selMusyrif.value}`
      );
      this.siswa = resp.data;
      this.$q.loading.hide();
    },
    async simpan() {
      let merge = "";
      merge =
        this.setoran.dariSurat +
        "%" +
        this.setoran.dariAyat +
        "%" +
        this.setoran.hinggaSurat +
        "%" +
        this.setoran.hinggaAyat;

      let bundle = {};
      bundle.setoran = merge;
      bundle.halaman_awal = this.setoran.halaman_awal;
      bundle.halaman_akhir = this.setoran.halaman_akhir;
      bundle.tanggal = this.date;

      let nama = "";
      let id = 0;
      if (this.user.is_super_user == "1") {
        nama = this.selMusyrif.label;
        id = this.selMusyrif.value;
      } else {
        nama = this.user.nama;
        id = this.user.id;
      }
      await this.$http.post(
        `/penilaian/tahfidz/tambah/${
          this.selSiswa.value
        }/${localStorage.getItem("id_tahun_ajaran")}/${nama}/${id}`,
        bundle
      );

      this.getSetoran();
      this.$q.notify({
        message: "Penilaian Berhasil Disimpan!",
        color: "positive",
      });
    },
    async edit() {
      let merge = "";
      merge =
        this.setoran.dariSurat +
        "%" +
        this.setoran.dariAyat +
        "%" +
        this.setoran.hinggaSurat +
        "%" +
        this.setoran.hinggaAyat;

      let bundle = {};
      bundle.setoran = merge;
      bundle.halaman_awal = this.setoran.halaman_awal;
      bundle.halaman_akhir = this.setoran.halaman_akhir;
      bundle.tanggal = this.date;
      await this.$http.put(`/penilaian/tahfidz/ubah/${this.patok.id}`, bundle);

      this.getSetoran();
      this.$q.notify({
        message: "Penilaian Berhasil Diubah!",
        color: "positive",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
